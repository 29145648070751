.custom-modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .custom-modal-content {
    position: fixed;
    top: 0;
    overflow-y: auto !important;
  
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 82vw;
    max-width: 100%;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .custom-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
  }
  