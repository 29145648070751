.recommendations-container {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-section {
  text-align: left;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: "Object Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 10px;
}

.description {
  margin-bottom: 20px;
  font-size: 1rem;
  margin-bottom: 2rem;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgb(83, 83, 100);
}

.sign-in-button {
  border: 1px solid #0041d9 !important;
  border-radius: 12px !important;
  padding: 9px 16px !important;
  text-transform: none;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px;
  width: auto;
  max-width: 200px;
  text-align: center;
  margin: 0;
  color: rgb(0, 65, 217) !important;
  background-color: rgb(255, 255, 255);
  transition-property: background-color, border-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  cursor: pointer;
  box-shadow: none;
  outline: none;
}

.sign-in-button:hover,
.sign-in-button:focus {
  background-color: rgb(255, 255, 255);
  border-color: rgb(0, 65, 217);
  color: rgb(0, 65, 217);
}
.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommended-image {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .text-section {
    text-align: center;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .recommended-image {
    width: 100%;
    height: auto;
  }
}
