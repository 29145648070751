.search-page {
  display: flex;
  flex-direction: column;
}
.search-results-block {
  display: flex;
}
.google-maps {
  width: 100%;
}
.search-results {
  width: min-content;
}
.search-header {
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  gap: 0.8rem;
}
.search-input-field button {
  width: 1.4rem;
  padding: 0px;
}
.search-input-field svg {
  width: 1rem;
}
.search-input-field input {
  padding: 6px;
  width: 25vw;
}
.drowndown-btn {
  text-transform: capitalize !important;
  /* font-size: 0.7rem; */
  color: #000000 !important;
  padding: 0rem 2rem !important;
  border-color: #bcbcbc !important;
}
.search-filter-btn {
  color: #ffffff !important;
  padding: 0rem 0.5rem !important;
  box-shadow: none !important;
}
.property-card {
  width: 20rem;
  height: 17rem;
}

.property-image-slider {
  height: 70%;
}
.property-image-slider .slick-list,
.property-image-slider .slick-track {
  height: 100%;
  width: 100%;
}
.property-image-slider .slick-list img {
  height: 100%;
}
.slick-dots li button::before,
.slick-active button::before {
  /* display: inline-block; */
  /* width: 6px;
    height: 6px; */
  color: rgb(255, 255, 255) !important;
  border-radius: 50%;
  box-shadow: none;
  /* vertical-align: middle; */
  transition: 0.15s linear;
  /* margin: 0px 4px; */
  opacity: 0.6;
}
.slick-dots li::marker {
  color: white;
}
.slick-dots {
  width: 100%;
  max-width: 6rem;
  overflow: auto;
  scrollbar-width: none;
  white-space: nowrap;
  padding: 0 0 20px !important;
  list-style: none;
  /* border: 1px solid #ddd; */
  left: 0 !important;
  right: 0 !important;
  /* bottom: -50px !important; */
  margin: auto !important;
}
.card-property-photo,
.slick-slide,
.slick-slide div {
  width: 100%;
  height: 100%;
}
.card-property-photo {
  position: relative;
}
.propertylike-icon {
  position: absolute;
  width: 45px !important;
  height: 45px !important;
  top: 0;
  color: white;
  right: 0;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 2px 4px);
}
.flex-field-text {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.75rem;
  background-color: rgba(10, 10, 20, 0.6);
  border-radius: 999px;
  color: rgb(255, 255, 255);
  display: inline-block;
  padding: 2px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  text-transform: none;
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}
.price-and-menu-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-property-address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(42, 42, 51);

  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.card-property-basic-data {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.search-result-cards {
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.property-texts {
  padding: 0.5rem 0.5rem;
}
.more-icon-btn {
  padding: 0.2rem !important;
}
.searchresults-spinner-block {
  width: 100%;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-numbers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-results-text {
  margin: 1rem;
}
.search-result-h2 {
  font-size: 20px;
  font-weight: 600;
  /* color: rgb(167, 166, 171); */

  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
}
.property-length {
  font-size: 14px;
  font-weight: 600;
  color: rgb(42, 42, 51);
}
.sort-property-div:hover {
  text-decoration: underline;
}
.sort-property-div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(13, 69, 153);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
