.description-root {
    padding: 2rem;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .image-item {
    position: relative;
  }
  
  .image-item.large {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  .image-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .show-more-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
    color: black;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
    font-weight: 700;
    height: 36px;
    width: 195px;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(167, 166, 171);
  }
  
  .show-more-icon {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  
  .property-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 1rem;
  }
  
  .property-details {
    width: 70%;
  }
  
  .property-details h1 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .property-details p {
    margin: 8px 0;
  }
  
  .property-summary p{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .property-summary {
    display: flex;
    gap: 16px;
    font-weight: bold;
  }
  
  .estimation {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    /* padding: 5px 10px; */
    width: fit-content;
  }
  
  .estimation-content {
    background-color: rgb(224, 242, 255);
    border-radius: 8px;
    width: fit-content;
    padding: 5px 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
  }
  
  .est-value {
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .edit-icon {
    width: 20px !important;
    height: 20px !important;
    align-items: center;
  }
  
  .get-prequalified-text {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #0d4599;
    margin-left: 2rem;
  }
  
  .additional-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  
    margin: 20px 0;
  }
  
  .additional-details p {
    margin: 0;
    color: gray;
    font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
    font-weight: 400;
    font-size: 16px;
    background-color: rgb(246, 246, 250);
    border-radius: 4px;
    padding: 8px;
  }
  
  .gray-text {
    color: gray;
  }
  
  hr {
    border: 0;
    border-top: 1px solid #e6e6e6;
  }
  
  .additional-info {
    margin-top: 20px;
  }
  
  .additional-info .info-item {
    margin: 5px 0;
  }
  .info-item{
    margin-bottom: 1rem;
  }
  .map-section, .travel-section {
    margin-top: 20px;
  }
  
  .map-section h2, .travel-section h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  /* Make this home a reality */
  .make-home-reality {
    margin-top: 20px;
    /* padding: 0px 20px; */
  }
  
  .reality-cards {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;
  
  }
  
  .reality-card {
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .reality-icon {
    font-size: 36px;
    color: #007bff;
    margin-right: 12px;
  }
  
  .reality-card h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .reality-card p {
    margin: 4px 0 0 0;
    color: gray;
  }
  
  /* Facts & features */
  
  .facts-features {
    margin-top: 20px;
    padding: 0px 20px;
  }
  
   /* Travel Section  */
  
  .travel-section {
    margin-top: 20px;
  }
  
  .add-destination input {
    width: 100%;
    padding: 10px;
    /* margin-bottom: 20px; */
    /* border: 1px solid #ddd; */
    border-radius: 5px;
  }
  
  .affordability {
    background: #e8f4fd;
    padding: 20px;
    border-radius: 5px;
    margin: 20px;
    /* margin-bottom: 20px; */
  }
  
  .affordability h3 {
    margin: 0 0 10px 0;
  }
  
  .affordability p {
    margin: 0 0 10px 0;
  }
  
  .affordability span {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    color: #777;
  }
  
  .prequalify-button{
    margin-right: 10px;
    padding: 10px 20px;
    border: 1px  solid #007aff;
    color: #007aff;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .calculate-button {
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    background: #007aff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .calculate-button:hover{
    text-decoration: underline;
  
  }
  
  .calculate-button {
    background:none;
    color: #007aff;
  }
  
  .tour-section {
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  
  }
  
  .tour-section h3 {
    margin: 0 0 10px 0;
  }
  
  .tour-section p {
    margin: 0 0 10px 0;
  }
  
  .see-times-button {
    padding: 10px 20px;
    border: none;
    background: #007aff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .contact-section {
    margin-top: 20px;
  }
  
  .contact-button,
  .prequalify-button {
    margin-right: 10px;
  }
  
  .contact-agent {
    position: sticky;
    top: 16px;
    align-self: start;
    padding: 20px;
    margin-top: 24px;
    border: 1px solid rgb(209, 209, 213);
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .contact-agent-button {
    padding: 12px 50px;
    color: #007bff;
    font-weight: bold !important;
    border-radius: 4px;
    border: 1px solid #007bff !important ;
    cursor: pointer;
    text-transform: capitalize !important;
    font-size: 16px;
    width: 100% !important;
  }
  
  
  .all-images-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .close-popup-button {
    display: block;
    margin: 20px auto 0;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .close-popup-button:hover {
    background-color: #0056b3;
  }
  
  .special-text {
    margin: 0;
    font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: black;
    text-align: justify;
  }
  
  .toggle-text {
    cursor: pointer;
    color: rgb(13, 69, 153);
    font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    transition: 0.3s;
  }
  
  .arrow {
    font-size: 18px;
    margin-left: 8px;
    transition: transform 0.3s;
  }
  
  .arrow.up {
    transform: rotate(180deg);
  }
  
  .arrow.down {
    transform: rotate(0deg);
  }
  .property-details-block-1{
    display: flex;
    justify-content: space-between;
  }
.property-unit{

  font-size: 20px;
  line-height: 32px;
  color: rgb(42, 42, 51);
  font-weight: 400;
}
.property-value{
  font-size: 32px;
}
.property-detail-loader{
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-destination-field{
  width: 100% !important;
}
.van-icon{
  fill:#006aff !important;
}
.facts-and-features{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px 0px 16px;
}
.facts-and-features-title{
  margin-bottom: 24px;
  padding: 8px 0px 8px 8px;
  background: rgb(246, 246, 250);
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
.feature-seperator{
  width: 100%;
  height: 1px;
  margin: 2rem 0px;
  color: rgb(209, 209, 213);
}
.home-to-reality{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.home-to-reality-btn:hover{
  background-color: rgb(178, 209, 253) !important;
}
.home-to-reality-btn{
  width: 100%;
  text-align: start;
  border-radius: 4px !important;
  padding: 16px !important;
  background-color: rgb(255, 255, 255) !important;
  cursor: pointer !important;
  outline: none !important;
  position: relative !important;
  transition-property: background-color, box-shadow !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease !important;
  border-width: 1px 1px 3px !important;
  border-style: solid !important;
  border-color:rgb(209, 209, 213) rgb(209, 209, 213) rgb(176 176 176) !important;
  border-image: initial !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px !important;
}
.home-to-reality-txt-block{
  width: 100%;
}
.home-to-reality-icon, .home-to-reality-icon svg {
  font-size: 44px !important;
}
.home-to-reality-txt-block p{
  color:#2a2a33;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}
.home-to-reality-txt-block h4{
  color: rgb(42, 42, 51);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
}
.emv-block{
  width: 100%;
  background-color: #f6f6fa;
  padding: 16px 12px 16px 24px;
  border-radius: 4px;
}
.emv-title{
  color: rgb(42, 42, 51) !important;
  -webkit-font-smoothing: antialiased !important;
  text-transform: none !important;
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0px !important;
  border-bottom-style: dashed !important;
  border-bottom-color: rgb(0, 106, 255) !important;
  /* border-bottom: 1px; */
}
.emv{
  display: flex;
  /* gap: 1rem; */
  justify-content: space-around;
  gap: 1rem;
  padding: 1rem;
  text-align: center;
}




/* monthly payment calculator start  */

.monthly-payment-cal-title{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 0px 0px 16px;
}
.monthly-payment-cal-main{
  display: flex;
  justify-content: space-between;
}
.monthly-payment-cal-block-1{
  width: 50%;
}
.monthly-payment-cal-block-2{
  width: 50%;
}

.estimate-monnthly-payment svg{
  width: .7em !important;
}
.estimate-monnthly-payment{
  color: rgb(89, 107, 130);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  margin: 0px;
}
.monthly-payment-price{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 0px 0px 16px;
}
.customize-monthly-payment-btn{
  color: rgb(0, 106, 255) !important;
  text-transform: capitalize !important;
  width: 100% !important;
  font-weight: 700 !important;  
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif !important;
}
.monthly-payment-note{
  color: rgb(89, 107, 130);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin: 0px;
}
.down-payment-title{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
  
}




/* monthly payment calculator end  */
/* down payment start  */
.down-payment-main{
  display: flex;
  gap: 1rem;
}
.down-payment-cal-icon{
  width: 2em !important;
  height: 2em !important;
  margin: 9px 0px !important;
}
.down-payment-text{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 0px;
}
.down-payment-btn{

  font-weight: 700 !important;  
  color: rgb(0, 106, 255) !important;
  text-transform: capitalize !important;
  width: 50% !important;
  
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif !important;
}
.down-payment-source-text{

  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
}

/* down payment end  */

/* neighborhood start  */

.neighborhood-txt{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}

/* neighborhood end  */

/* getting around start  */

.getting-around-title{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}
.getting-around-icon{
      /* padding: 1rem; */
      fill: white !important;
      width: 1.5em !important;
      height: 1.5em !important;
}
.getting-around-icon-block{
  padding: 1rem;
  width: min-content;
  height: min-content;
  background: green;
  border-radius: 100%;
}
.getting-around-main{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.getting-around-tile{
  display: flex;
  gap:1rem;
  width: 45%;
}
.getting-around-review-txt{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 0px;
}
.getting-around-info-block p{
  margin-bottom: 0px;
}


/* nearby schools start */
.nearby-schools-title{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}
.school-rating-title, .school-listed-by-agent{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  margin: 0px 0px 16px;
  font-size: 20px;
  font-weight: 600;
}
.nearby-schools-main{
  display: flex;
  gap:1rem;
}
.nearby-schools-block-1, .nearby-schools-block-2{
  width: 48%;
  
}
.nearby-schools-block-2 p{
  margin: 0px 0px 16px;
  padding: 0px;
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.school-name{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: rgb(13, 69, 153);
  text-decoration: none;
  margin-top: 0px;
}
.nearby-school-info-block{
  display: flex;
  gap: 1rem;
  font-size: 14px;
}
.nearby-school{
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}
.school-rating-score-block{
  width: max-content;
  height: max-content;
  padding: 1rem;
  border-radius: 100%;
  background: rgb(0, 23, 81);
  color: rgb(255, 255, 255);
}
.school-rating-score span{
  font-weight: 700;
  font-size: 20px;
}
.school-rating-score{
  font-size: 14px;

}
.near-by-schools-list{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}




/* Local experts  */
.local-experts-title{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}
.local-experts-text{

  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 0px 24px;
}
.browse-experts-btn{
  color: rgb(13, 69, 153);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
} 



/* Local legal css */
.local-legals{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}
.local-legal-main p{
  margin-top:0px;
}
.local-legal-main{
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1rem .5rem;

}
.local-legal-txt{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.local-legal-balance-icon{
  width: 1.5em !important;
  height: 1.5em !important;
}



/* Open house css  */
.open-house{
  border-radius: 4px;
  padding: 16px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(209, 209, 213);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
  width: 14rem;
}
.open-house-date{
  color: rgb(42, 42, 51);
  -webkit-font-smoothing: antialiased;
  text-transform: none;
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
}
.open-house-time{
  color: rgb(42, 42, 51);
  -webkit-font-smoothing: antialiased;
  text-transform: none;
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
}
.open-house-cal-link{
  color: #007aff;
  -webkit-font-smoothing: antialiased;
  /* text-transform: none; */
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
}
.open-house-main{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1rem 0rem;
}
.open-house-title{
  margin: 1rem 0rem;
}



/* Footer css  */
.content-container {
  padding: 20px 0px;
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}

.container-style {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 36px;
}

.blue-color {
  color: rgb(13, 69, 153)
}

.sell-or-share {
  text-align: center;
  color: rgb(13, 69, 153)
}

.footer-content {
  padding: 20px;
  font-size: 10px;
  text-align: center;
}

.footer-paragraph {
  text-align: center;
  padding: 0% 15%
}

.footer-links {
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}

.show-more-button {
  color: rgb(13, 69, 153);
  text-decoration: none;
  cursor: pointer;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: inline-flex;
  box-shadow: none;
  border: 0px;
  background: none;
  padding: 0px;
  appearance: none;
}

.social-links {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 2rem 0rem ;
}

.social-icons-color {
  color: rgb(0, 106, 255);
  line-height: 45px;
  margin-left: 8px;
}

.social-icons {
  display: inline-block;
  vertical-align: top;
  stroke: currentcolor;
  fill: currentcolor;
  height: 30px;
  width: 32px;
}

.follow-us {
  display: flex;
  align-items: center;
}

.follow-us-icons {
  display: flex;
  width: 92px;
  height: 30px;
}

.footer-image-style{
  max-width: 100%;
  height: auto;
}

.cls-1 {
  fill: #0c4499;
}

.cls-3 {
  fill: #f2af34;
}

.cls-4 {
  fill: #136ffb;
}

.cls-5 {
  fill: #c1edfe;
}

.cls-6 {
  fill: #fff;
}

.cls-7 {
  fill: #bfecff;
}

.cls-9 {
  fill: #c1e8fa;
}

.cls-10 {
  fill: #194995;
}

.cls-11 {
  fill: #c0e7fa;
}
.nearby-neighborhoods{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}
.nearby-zipcode {
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}
.schenectady-topics{
  color: rgb(42, 42, 51);
  font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0px 16px;
}
.a-tag-footer{
 color: rgb(13, 69, 153);
 line-height: 32px;
 font-weight: 400;
}
.a-tag-bottom-footer{
  color: rgb(13, 69, 153)  !important; 
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;
}
.footer-logo{
  width: 10rem;
}
.search-experts-results{
  width: 100%;
}
.contact-agent-main{
  width: 30%;
}

@media screen and (max-width: 768px) {
  .property-info{
    flex-direction: column-reverse;
  }
  .property-details{
    width: 100%;
  }
  .contact-agent-main{
    width: 100%;
  }
}
.experts-map{
  width: 100%;
  height: 400px;
}
.price-tag{
  background-color: #a3000b;
  color: white;
  padding: 5px;
  border-radius: 100%;
  font-weight: 700;
  font-size: 14px;
}
.property-icon{
  width: 1.5rem;
  height: 1.5rem;
  background:#a3000b;
  border-radius: 5px;
  color: #ffffff;
  padding: 2px;
}
.property-icon-on-map{
  background-color: #a3000b;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 10px;
}
.expert-infowindow{
  min-width: 11rem;
  padding: 0rem 1rem 1rem 1rem;
}