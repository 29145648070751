.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.left-section,
.right-section {
  display: flex;
  align-items: center;
}

.right-section {
  display: flex;
  align-items: center;
}

.icon-with-text {
  display: flex;
  align-items: center;
}

.center-section {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.zillow-logo {
  height: 3rem;
}
.property-detail-right-header-item {
  color: rgba(0, 0, 0, 0.54) !important;
  text-transform: capitalize !important;
}
.property-detail-left-header-item {
  color: rgba(0, 0, 0, 0.54) !important;
  text-transform: capitalize !important;
}

@media (max-width: 600px) {
  .center-section {
    display: none;
  }

  .icon-with-text {
    display: flex;
  }

  .hide-on-mobile {
    display: none;
  }

  .back-text {
    display: inline;
  }
}
