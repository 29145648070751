.facts-and-features--list{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
}
.facts-and-features--sub-list{
    width: 48%;
}
.facts-and-features--ul{
    margin: 1rem 2rem ;
}
.facts-and-features--ul li{
    /* margin: .5rem 0rem ; */
    color: rgb(42, 42, 51);
    font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
}
.facts-and-featues-hide{
    height: 40rem;
    overflow: hidden;
}
.facts-and-featues-show{
    height: max-content;
    /* overflow: hidden; */
}