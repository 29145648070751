.cards-container {
  padding: 48px 150px;
  margin: 0 auto;
  background-color: rgb(247, 247, 247);
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border-radius: 20px !important;
  padding: 30px 16px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(205, 205, 211) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 15px !important;
  height: 100%;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-image-container {
  flex-shrink: 0;
  margin-bottom: 20px;
}

.card-image {
  width: 160px;
  height: 160px;
  margin: 0 auto 15px;
}

.card-text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center; 
  margin-bottom: 30px;
}

.card-button {
  border: 1px solid #0041d9 !important;
  border-radius: 12px !important;
  padding: 9px 16px !important;
  text-transform: none;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  width: auto;
  max-width: 200px;
  text-align: center;
  margin: 0;
  color: rgb(0, 65, 217) !important; 
  background-color: rgb(255, 255, 255);
  transition-property: background-color, border-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  text-transform: none !important;
}

/* Hover and focus styles */
.card-button:hover, .card-button:focus {
  background-color: rgb(255, 255, 255);
  border-color: rgb(0, 65, 217);
  color: rgb(0, 65, 217);

}



.card-title {
  margin-bottom: 10px;
  font-family: "Object Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif !important;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
}

.card-description {
  margin-bottom: 20px;
  color: rgb(17, 17, 22);
  -webkit-font-smoothing: antialiased;
  text-transform: none;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  flex-grow: 1;
}



@media (max-width: 1020px) and (min-width: 478px) {
  .card {
    flex-direction: row;
    text-align: left;
  }

  .card-content {
    flex-direction: row;
  }

  .cards-container {
    padding: 20px 30px;
    margin: 0 auto;
    background-color: rgb(247, 247, 247);
  }

  .card-image-container {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .card-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to start */
    align-items: flex-start; 
    flex-grow: 1; 
  }

  .card-title {
    font-size: 24px !important;
    text-align: left; 
  }

  .card-description {
    font-size: 16px !important;
    text-align: left; 
  }

  .card-button {
    font-size: 12px;
    text-align: center; 
  }
}

@media (max-width: 925) {
  .card {
    flex-direction: row;
    text-align: left;
  }

  .card-content {
    flex-direction: row;
  }

  .cards-container {
    padding: 20px 30px;
    margin: 0 auto;
    background-color: rgb(247, 247, 247);
  }

  .card-image-container {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .card-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start; 
    flex-grow: 1; 
  }

  .card-title {
    font-size: 24px !important;
    text-align: left; 
  }

  .card-description {
    font-size: 16px !important;
    text-align: left; 
  }

  .card-button {
    font-size: 12px;
    text-align: center; 
  }
}




@media (max-width: 425px) {
  .cards-container {
    padding: 20px 20px;
    margin: 0 auto;
    background-color: rgb(247, 247, 247);
  }
}

@media (min-width: 426px) and (max-width: 767px) {
  .cards-container {
    padding: 20px 30px;
    margin: 0 auto;
    background-color: rgb(247, 247, 247);
  }
}


@media (max-width: 1480px) {
  .cards-container {
    padding: 20px 30px; 
  }

  .card {
  }

  .card-content {
  }

  .card-image-container {
    margin-right: 0; 
    margin-bottom: 20px; 
  }

  .card-title {
    font-size: 18px; 
  }

  .card-description {
    font-size: 14px; 
  }

  .card-button {
    font-size: 14px; 
    padding: 7px 14px;
  }
}
