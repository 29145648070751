.footer-container {
  background-color: white;
  padding: 20px 0;
}

.footer-heading {
  margin-bottom: 40px;
}
.footer-heading h6 {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 150px;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 900;
  font-size: 16px;
}

.footer-text {
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgb(83, 83, 100);
}

.footer-text p {
  font-family: "Inter", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
}

.non-link-text {
  display: inline-block;
  color: #6b0dc3;
  cursor: default;
  text-decoration: underline;
}

.link-text {
  display: inline-block;
  color: blue;
  cursor: default;
  text-decoration: underline;
}
.Personal-information {
  display: flex;
  justify-content: center;
  margin: 10px 0px !important;
}

.Personal-information-link {
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  text-decoration: none !important;
}

.footer-links {
  display: flex;
  justify-content: center;
  /* margin: 10px 20px; */
}
.footer-links .footer-link {
  margin: 0 10px;
  text-decoration: none;
  color: #000;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.footer-divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.footer-link-item {
  display: flex;
  align-items: center;
  position: relative;
}

.footer-link-item:not(:last-child)::after {
  content: "";
  display: block;
  width: 1px;
  height: 24px;
  background-color: #ccc;
  margin-left: 10px;
  margin-right: 10px;
}

.footer-link {
  margin: 0 10px;
  text-decoration: none;
  color: #000;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.dropdown-icon {
  margin-top: 7px;
  margin-left: 5px;
  width: 20px;
  height: 20px;
}

.footer-social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.footer-social .footer-social-link {
  margin: 0 5px;
}

.follow-us {
  margin: 0 10px;
  font-style: italic;
  color: gray;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
}

.center {
  text-align: center;
}

.footer-apps {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-apps .footer-app-icon {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-links .footer-link {
    margin: 10px 0;
  }
  .footer-heading h6 {
    margin-top: 15px !important;
  }

  .footer-text {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 599px) {
  .footer-links .MuiGrid-item {
    width: 50%;
  }

  .footer-logo {
    margin: 10px;
  }
  .footer-social {
    flex-direction: column;
    align-items: center;
  }
  .footer-social-links .follow-us {
    margin-bottom: 5px;
  }

  .footer-social .footer-social-link,
  .footer-social .follow-us {
    margin: 5px 0;
  }

  .Personal-information {
    margin: 5x 0px !important;
  }
  .Personal-information-link {
    font-size: 14px !important;
  }
}



.footer-social-links {
  display: flex;
  align-items: center; 
  justify-content: center; 
  flex-wrap: wrap; 
}

.footer-social-links .follow-us {
  margin: 0 10px;
  font-style: italic;
  color: gray;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
}


