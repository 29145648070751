/* Existing Styles */
.header-container-main {
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.custom-appbar {
  background-color: white !important;
  padding: 10px;
  box-shadow: none !important;
  border-bottom: 1px solid rgb(167, 166, 171);
}

.logo-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.logo {
  /* width: 160px; */
  height: 4rem;
}

.nav-links-left {
  margin-right: auto;
  /* padding-left: 80px; */
}

.nav-links-right {
  margin-left: auto;
  /* padding-right: 80px; */
}

.nav-links-left,
.nav-links-right {
  /* display: flex; */
  align-items: center;
  gap: 40px;
  font-size: 16px;
  color: rgb(17, 17, 22);
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
}

.menu-icon-button {
  color: black;
}

.menu-icon {
  color: black;
}

.nav-link {
  cursor: pointer;
  color: rgb(42, 42, 51) !important;
  text-decoration: none;
  padding: 14px;
  /* font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif !important; */
  font-size: 16px !important;
      font-family: "Open Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;

}

.nav-link:hover {
  color: #006aff;
}

.drawer-paper {
  width: 100%;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
  background-color: white;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.drawer-logo-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.drawer-logo {
  width: 110px;
  height: 24px;
}

.close-icon-button {
  color: black;
}

.close-icon {
  color: black;
}

.content-section {
  background-size: cover;
  background-position: center -50px;
  background-repeat: no-repeat;
  background-image: url("../images/Home_image.webp");
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: white;
}

.content-text {
  margin-left: 150px;
}

.content-text h1 {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Object Sans", "Adjusted Arial", Tahoma, Geneva, sans-serif;
  font-weight: 900;
  font-size: 60px;
  line-height: 72px;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid rgb(205, 205, 211);
  display: none;
  padding: 10px 0;
}

.sticky-header.visible {
  display: flex;
  justify-content: center;
}

.sticky-content {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 150px;
  width: 100%;
}

.sticky-logo {
  width: 130px;
  height: 45px;
}
.sticky-logo-container {
  padding-right: 100px;
  display: flex;
  align-items: center;
}

.sticky-header .search-bar {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 12px;
  margin: 5px;
  width: 100% !important;
  max-width: 700px;
  border: 1px solid rgb(205, 205, 211);
  box-shadow: none;
  position: relative;
}

.sticky-header .search-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 14px 24px;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
  color: #111116;
  border-radius: 12px;
}

.sticky-header .search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Styles for Content Section's Search Bar */
.content-section .search-bar {
  display: flex;
  align-items: center;
  background: white;
  padding: 15px 10px;
  border-radius: 12px;
  margin-top: 20px;
  width: 100% !important;
  max-width: 800px !important;
  border: 1px solid rgb(205, 205, 211);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-section .search-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  font-family: Inter, "Adjusted Arial", Tahoma, Geneva, sans-serif;
  color: #111116;
  border-radius: 12px;
}

.content-section .search-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .nav-links-left,
  .nav-links-right {
    display: none;
  }

  .content-text {
    text-align: center;
    margin-left: 0;
    width: 100%;
  }

  .content-text h1 {
    font-size: 2em;
    line-height: 1.4;
  }

  .content-section {
    background-position: center center;
    background-size: cover;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-section .search-bar {
    display: flex;
    align-items: center;
    background: white;
    padding: 10px 10px;
    border-radius: 12px;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    width: 90% !important;
    max-width: 500px !important;
  }
}

@media (max-width: 900px) {
  .sticky-content {
    padding: 0px 10px;
  }

  .sticky-logo {
    width: 80px;
    height: 45px;
  }
  .sticky-logo-container {
    padding-right: 10px;
  }
  .sticky-header .search-bar {
    margin: 5px;
  }

  .sticky-header .search-input {
    padding: 10px 16px;
  }

  .sticky-header .search-icon {
    right: 5px;
  }

}

.menubar{
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* box-shadow: none !important; */
}